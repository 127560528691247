import React, { useEffect, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";

import Aelo_model from "./glb/aloevera.glb";
import Lavender_model from "./glb/lavender.glb";
import Tulsi_model from "./glb/tulsi.glb";
import Neem_model from "./glb/neem.glb";
import Jasmine_model from "./glb/jasmine.glb";
import Lotus_model from "./glb/lotus.glb";
import Acacia_model from "./glb/acacia.glb";
import Hibiscus_model from "./glb/hibiscus.glb";

import Indoor_model from "./glb/indoor_plant.glb";
import GardenModel from "./glb/garden.glb";
import Bot_Model from "./glb/bot.glb";

import GardenARModel from "./glb/garden_ar.glb";
import SkyModel from "./glb/sky.glb";

export function Aloevera(props) {
  const { nodes, materials } = useGLTF(Aelo_model)
  return (
    <group {...props} dispose={null} scale={3}>
      <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} />
    </group>
  )
}
useGLTF.preload(Aelo_model)

export function Lavender(props) {
  const { nodes, materials } = useGLTF(Lavender_model);
  return (
    <group {...props} dispose={null} scale={3.2} position={[-0.5, 0.4, -0.5]}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <mesh
            geometry={nodes.defaultMaterial.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_1.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_2.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_3.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_4.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_5.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_6.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_7.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_8.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_9.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_10.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_11.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_12.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_13.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_14.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_15.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_16.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_17.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_18.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_19.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_20.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_21.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_22.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_23.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_24.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_25.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_26.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_27.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_28.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_29.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_30.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_31.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_32.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_33.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_34.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_35.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_36.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_37.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_38.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_39.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_40.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_41.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_42.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_43.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_44.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_45.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_46.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_47.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_48.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_49.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_50.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_51.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_52.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_53.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_54.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_55.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_56.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_57.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_58.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_59.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_60.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_61.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_62.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_63.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_64.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_65.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_66.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_67.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_68.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_69.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_70.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_71.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_72.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_73.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_74.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_75.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_76.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_77.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_78.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_79.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_80.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_81.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_82.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_83.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_84.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_85.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_86.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_87.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_88.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_89.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_90.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_91.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_92.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_93.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_94.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_95.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_96.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_97.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_98.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_99.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_100.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_101.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_102.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_103.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_104.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_105.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_106.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_107.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_108.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_109.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_110.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_111.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_112.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_113.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_114.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_115.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_116.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_117.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_118.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_119.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_120.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_121.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_122.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_123.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_124.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_125.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_126.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_127.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_128.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_129.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_130.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_131.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_132.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_133.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_134.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_135.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_136.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_137.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_138.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_139.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_140.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_141.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_142.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_143.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_144.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_145.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_146.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_147.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_148.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_149.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_150.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_151.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_152.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_153.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_154.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_155.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_156.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_157.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_158.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_159.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_160.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_161.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_162.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_163.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_164.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_165.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_166.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_167.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_168.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_169.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_170.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_171.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_172.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_173.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_174.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_175.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_176.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_177.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_178.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_179.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_180.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_181.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_182.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_183.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_184.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_185.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_186.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_187.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_188.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_189.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_190.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_191.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_192.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_193.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_194.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_195.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_196.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_197.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_198.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_199.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_200.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_201.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_202.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_203.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_204.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_205.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_206.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_207.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_208.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_209.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_210.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_211.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_212.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_213.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_214.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_215.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_216.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_217.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_218.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_219.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_220.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_221.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_222.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_223.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_224.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_225.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_226.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_227.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_228.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_229.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_230.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_231.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_232.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_233.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_234.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_235.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_236.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_237.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_238.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_239.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_240.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_241.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_242.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_243.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_244.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_245.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_246.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_247.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_248.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_249.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_250.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_251.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_252.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_253.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_254.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_255.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_256.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_257.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_258.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_259.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_260.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_261.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_262.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_263.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_264.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_265.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_266.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_267.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_268.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_269.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_270.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_271.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_272.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_273.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_274.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_275.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_276.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_277.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_278.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_279.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_280.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_281.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_282.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_283.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_284.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_285.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_286.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_287.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_288.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_289.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_290.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_291.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_292.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_293.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_294.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_295.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_296.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_297.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_298.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_299.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_300.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_301.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_302.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_303.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_304.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_305.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_306.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_307.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_308.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_309.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_310.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_311.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_312.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_313.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_314.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_315.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_316.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_317.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_318.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_319.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_320.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_321.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_322.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_323.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_324.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_325.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_326.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_327.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_328.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_329.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_330.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_331.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_332.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_333.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_334.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_335.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_336.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_337.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_338.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_339.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_340.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_341.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_342.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_343.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_344.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_345.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_346.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_347.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_348.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_349.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_350.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_351.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_352.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_353.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_354.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_355.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_356.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_357.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_358.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_359.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_360.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_361.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_362.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_363.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_364.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_365.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_366.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_367.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_368.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_369.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_370.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_371.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_372.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_373.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_374.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_375.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_376.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_377.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_378.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_379.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_380.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_381.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_382.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_383.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_384.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_385.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_386.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_387.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_388.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_389.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_390.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_391.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_392.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_393.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_394.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_395.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_396.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_397.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_398.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_399.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_400.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_401.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_402.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_403.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_404.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_405.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_406.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_407.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_408.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_409.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_410.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_411.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_412.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_413.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_414.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_415.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_416.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_417.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_418.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_419.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_420.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_421.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_422.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_423.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_424.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_425.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_426.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_427.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_428.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_429.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_430.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_431.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_432.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_433.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_434.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_435.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_436.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_437.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_438.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_439.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_440.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_441.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_442.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_443.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_444.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_445.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_446.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_447.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_448.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_449.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_450.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_451.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_452.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_453.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_454.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_455.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_456.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_457.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_458.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_459.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_460.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_461.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_462.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_463.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_464.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_465.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_466.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_467.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_468.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_469.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_470.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_471.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_472.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_473.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_474.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_475.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_476.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_477.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_478.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_479.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_480.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_481.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_482.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_483.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_484.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_485.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_486.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_487.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_488.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_489.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_490.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_491.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_492.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_493.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_494.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_495.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_496.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_497.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_498.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_499.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_500.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_501.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_502.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_503.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_504.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_505.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_506.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_507.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_508.geometry}
            material={materials.aiStandardSurface2SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_509.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_510.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_511.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_512.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_513.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_514.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_515.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_516.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_517.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_518.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_519.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_520.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_521.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_522.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_523.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_524.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_525.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_526.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_527.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_528.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_529.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_530.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_531.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_532.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_533.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_534.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_535.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_536.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_537.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_538.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_539.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_540.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_541.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_542.geometry}
            material={materials.aiStandardSurface3SG}
          />
          <mesh
            geometry={nodes.defaultMaterial_543.geometry}
            material={materials.aiStandardSurface1SG}
          />
        </group>
      </group>
    </group>
  );
}
useGLTF.preload(Lavender_model);

export function Tulsi(props) {
  const { nodes, materials } = useGLTF(Tulsi_model)
  return (
    <group {...props} dispose={null} scale={3}>
      <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} />
    </group>
  )
}
useGLTF.preload(Tulsi_model)

export function Neem(props) {
  const { nodes, materials } = useGLTF(Neem_model)
  return (
    <group {...props} dispose={null} scale={3.3}>
      <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} />
    </group>
  )
}
useGLTF.preload(Neem_model)

export function Jasmine(props) {
  const { nodes, materials } = useGLTF(Jasmine_model)
  return (
    <group {...props} dispose={null} position={[0,-3.3,0]} scale={2}>
      <group rotation={[Math.PI / 2, 0, -1.42]} >
        <mesh geometry={nodes.Object_4.geometry} material={materials.Core_Mat} />
        <mesh geometry={nodes.Object_5.geometry} material={materials.Petal_Mat} />
        <mesh geometry={nodes.Object_6.geometry} material={materials.Leaf_Mat} />
        <mesh geometry={nodes.Object_7.geometry} material={materials.Stem_Mat} />
      </group>
    </group>
  )
}
useGLTF.preload(Jasmine_model)

export function Lotus(props) {
  const { nodes, materials } = useGLTF(Lotus_model)
  return (
    <group {...props} dispose={null} scale={3}>
      <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} />
    </group>
  )
}
useGLTF.preload(Lotus_model)

export function Acacia(props) {
  const { nodes, materials } = useGLTF(Acacia_model)
  return (
    <group {...props} dispose={null} scale={2.8}>
      <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} />
    </group>
  )
}
useGLTF.preload(Acacia_model)

export function Hibiscus(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF(Hibiscus_model)
  const { actions } = useAnimations(animations, group)
  // console.log("Animation Actions:", Object.keys(actions)) 
  useEffect(() => {
    actions["Scene"].setEffectiveTimeScale(0.2);
    actions["Scene"].play();
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null} scale={7} position={[0,2,0]}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]} scale={0.001}>
          <group name="1f9a0dad8b6c474bac9c43640e25bfcdfbx" rotation={[Math.PI / 2, 0, 0]}>
            <group name="Object_2">
              <group name="RootNode">
                <group name="Light" position={[271.143, -267.769, 233.056]} rotation={[2.042, 0.746, -2.035]} scale={100}>
                  <group name="Object_5" rotation={[Math.PI / 2, 0, 0]}>
                    <group name="Object_6" />
                  </group>
                </group>
                <group name="Camera" position={[1249.566, 294.225, -1676.617]} rotation={[Math.PI, -0.952, 2.946]} scale={100}>
                  <group name="Object_8" />
                </group>
                <group name="Petale" rotation={[-Math.PI / 2, 0, 0]} scale={100} />
                <group name="ArmaturePetale" rotation={[-Math.PI / 2, 0, 0]} scale={100}>
                  <group name="Object_11">
                    <primitive object={nodes._rootJoint} />
                    <group name="Object_13" rotation={[-Math.PI / 2, 0, 0]} scale={100} />
                    <skinnedMesh name="Object_14" geometry={nodes.Object_14.geometry} material={materials.Petale} skeleton={nodes.Object_14.skeleton} />
                    <skinnedMesh name="Object_15" geometry={nodes.Object_15.geometry} material={materials.Petale} skeleton={nodes.Object_15.skeleton} />
                    <skinnedMesh name="Object_16" geometry={nodes.Object_16.geometry} material={materials.tigeBoulette} skeleton={nodes.Object_16.skeleton} />
                    <skinnedMesh name="Object_17" geometry={nodes.Object_17.geometry} material={materials.Boulette} skeleton={nodes.Object_17.skeleton} />
                    <skinnedMesh name="Object_18" geometry={nodes.Object_18.geometry} material={materials.Pistil} skeleton={nodes.Object_18.skeleton} />
                    <skinnedMesh name="Object_19" geometry={nodes.Object_19.geometry} material={materials.Yellow} skeleton={nodes.Object_19.skeleton} />
                    <skinnedMesh name="Object_20" geometry={nodes.Object_20.geometry} material={materials.Sepale} skeleton={nodes.Object_20.skeleton} />
                    <skinnedMesh name="Object_21" geometry={nodes.Object_21.geometry} material={materials.stick} skeleton={nodes.Object_21.skeleton} />
                    <skinnedMesh name="Object_22" geometry={nodes.Object_22.geometry} material={materials.Leaf} skeleton={nodes.Object_22.skeleton} />
                    <skinnedMesh name="Object_23" geometry={nodes.Object_23.geometry} material={materials.material} skeleton={nodes.Object_23.skeleton} />
                    <skinnedMesh name="Object_24" geometry={nodes.Object_24.geometry} material={materials.Dirt} skeleton={nodes.Object_24.skeleton} />
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}
useGLTF.preload(Hibiscus_model)

export function ChatBot(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF(Bot_Model)
  const { actions } = useAnimations(animations, group)
  useEffect(() => {
    actions["Take 001"].setEffectiveTimeScale(0.2);
    actions["Take 001"].play();
  }, [actions]);
  return (
    <group ref={group} {...props} dispose={null} scale={250}>
      <group name="Sketchfab_Scene">
        <group name="Sketchfab_model" rotation={[-Math.PI / 2, 0, 0]}>
          <group name="9720a92d3b9a4d279bb7067fb9d66a02fbx" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
            <group name="Object_2">
              <group name="RootNode">
                <group name="Geo">
                  <group name="Body" position={[0, -0.195, 0]}>
                    <group name="Head" position={[0, 0.306, 0.088]} rotation={[0, Math.PI / 9, 0]}>
                      <group name="Head_Prop" position={[0, 0.089, -0.088]}>
                        <mesh name="Head_Prop_mat_michi_0" geometry={nodes.Head_Prop_mat_michi_0.geometry} material={materials.mat_michi} />
                      </group>
                      <mesh name="Head_mat_michi_0" geometry={nodes.Head_mat_michi_0.geometry} material={materials.mat_michi} />
                    </group>
                    <group name="AlaSup_Low1" position={[0.627, 0.389, 0]}>
                      <group name="Wing_Up_I_1" position={[-0.627, -0.427, 0]} rotation={[0, 0.436, 0]}>
                        <group name="Wing_Up_I" position={[0.515, 0.296, -0.012]}>
                          <mesh name="Wing_Up_I_mat_michi_0" geometry={nodes.Wing_Up_I_mat_michi_0.geometry} material={materials.mat_michi} />
                        </group>
                      </group>
                      <group name="Wing_Up_D_1" position={[-0.627, -0.427, 0]} rotation={[0, -0.436, 0]}>
                        <group name="Wing_Up_D" position={[-0.515, 0.296, -0.012]}>
                          <mesh name="Wing_Up_D_mat_michi_0" geometry={nodes.Wing_Up_D_mat_michi_0.geometry} material={materials.mat_michi} />
                        </group>
                      </group>
                    </group>
                    <group name="Wing" position={[0.675, 0.203, 0]}>
                      <group name="Wing_Down_I_1" position={[-0.675, -0.241, 0]} rotation={[0, 0.436, 0]}>
                        <group name="Wing_Down_I" position={[0.589, 0.199, -0.012]}>
                          <mesh name="Wing_Down_I_mat_michi_0" geometry={nodes.Wing_Down_I_mat_michi_0.geometry} material={materials.mat_michi} />
                        </group>
                      </group>
                      <group name="Wing_Down_D_1" position={[-0.675, -0.241, 0]} rotation={[0, -0.436, 0]}>
                        <group name="Wing_Down_D" position={[-0.589, 0.199, -0.012]} rotation={[-0.122, -0.592, -0.068]}>
                          <mesh name="Wing_Down_D_mat_michi_0" geometry={nodes.Wing_Down_D_mat_michi_0.geometry} material={materials.mat_michi} />
                        </group>
                      </group>
                    </group>
                    <group name="Shoulder" position={[0, 0.395, 0]}>
                      <group name="Shoulder_I" position={[0.394, -0.308, 0.056]} rotation={[-0.857, 0.002, -0.004]}>
                        <group name="Cannon_I" position={[0.058, 0.001, 0.052]} rotation={[-0.009, 0.131, 0.151]}>
                          <mesh name="Cannon_I_mat_michi_0" geometry={nodes.Cannon_I_mat_michi_0.geometry} material={materials.mat_michi} />
                        </group>
                        <mesh name="Shoulder_I_mat_michi_0" geometry={nodes.Shoulder_I_mat_michi_0.geometry} material={materials.mat_michi} />
                      </group>
                      <group name="Shoulder_D" position={[-0.394, -0.308, 0.056]} rotation={[-0.672, 0.275, 0.13]}>
                        <group name="Cannon_D" position={[0.394, 0.308, -0.056]}>
                          <mesh name="Cannon_D_mat_michi_0" geometry={nodes.Cannon_D_mat_michi_0.geometry} material={materials.mat_michi} />
                        </group>
                        <mesh name="Shoulder_D_mat_michi_0" geometry={nodes.Shoulder_D_mat_michi_0.geometry} material={materials.mat_michi} />
                      </group>
                    </group>
                    <group name="Cannon" position={[0, 0.395, 0]} />
                    <mesh name="Body_mat_michi_0" geometry={nodes.Body_mat_michi_0.geometry} material={materials.mat_michi} />
                  </group>
                </group>
              </group>
            </group>
          </group>
        </group>
      </group>
    </group>
  )
}
useGLTF.preload(Bot_Model)

export function Indoor(props) {
  const { nodes, materials } = useGLTF(Indoor_model)
  return (
    <group {...props} dispose={null} scale={4.1} position={[0, -3, 0]}>
      <mesh geometry={nodes.Mesh1_FrontColor_0.geometry} material={materials.PaletteMaterial001} />
    </group>
  )
}
useGLTF.preload(Indoor_model)

export function Garden(props) {
  const { nodes, materials } = useGLTF(GardenModel)
  return (
    <group {...props} dispose={null} scale={0.02} position={[0,-3,0]}>
      <mesh geometry={nodes.Bark01_1_Bark01_SHD_0.geometry} material={materials.Bark01_SHD} rotation={[Math.PI, 0, 0]} />
      <mesh geometry={nodes.Petal02_1_Petal02_su_SHD_0.geometry} material={materials.Petal02_su_SHD} rotation={[Math.PI, 0, 0]} />
      <mesh geometry={nodes.Petal04_1_Petal04_su_SHD_0.geometry} material={materials.Petal04_su_SHD} rotation={[Math.PI, 0, 0]} />
    </group>
  )
}
useGLTF.preload(GardenModel)

export function GardenAR(props) {
  const { nodes, materials } = useGLTF(GardenARModel)
  return (
    <group {...props} dispose={null} scale={5}>
      <mesh geometry={nodes.ground_close_08_ground_close_08_0.geometry} material={materials.ground_close_08} rotation={[-Math.PI / 2, 0, 0]} scale={0.001} />
      <mesh geometry={nodes.s_list_03_10kuwb_s_list_03_10kuw_0.geometry} material={materials.s_list_03_10kuw} position={[0.131, -0.029, -0.098]} rotation={[-Math.PI / 2, 0, 0]} scale={0.001} />
      <mesh geometry={nodes.s_list_04_10kuwa_s_list_04_10kuw_0.geometry} material={materials.s_list_04_10kuw} position={[0.48, -0.019, -0.46]} rotation={[-Math.PI / 2, 0, 0]} scale={0.001} />
      <mesh geometry={nodes.s_list_01_10kuwb_default_0.geometry} material={materials['default']} position={[0.46, -0.049, 0.591]} rotation={[-Math.PI / 2, 0, 0]} scale={0.001} />
      <mesh geometry={nodes.s_list_02_10kuwa_s_list_02_10kuw_0.geometry} material={materials.s_list_02_10kuw} position={[-0.01, -0.049, 0.377]} rotation={[-Math.PI / 2, 0, 0]} scale={0.001} />
      <mesh geometry={nodes.R4_micro_plants_0.geometry} material={materials.micro_plants} position={[-0.135, -0.026, -0.746]} rotation={[-Math.PI / 2, 0, 0]} scale={0.001} />
    </group>
  )
}
useGLTF.preload(GardenARModel)

export function Sky(props) {
  const { nodes, materials } = useGLTF(SkyModel)
  return (
    <group {...props} dispose={null}>
      <mesh geometry={nodes.Object_2.geometry} material={materials.M_Sky_Panning_Clouds2} rotation={[-Math.PI / 2, 0, 0]} />
    </group>
  )
}
useGLTF.preload(SkyModel)
